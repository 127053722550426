<template>
  <div class="home">
    <!-- <van-notice-bar
      left-icon="volume-o"
      :scrollable="hasNew"
      :text="hasNew ? $t('news') : $t('nonews')"
      @click="toChannel"
    /> -->
    <van-grid :border="false" :column-num="3">
      <van-grid-item to="/record">
        <template #icon>
          <img src="../assets/images/record.png" />
        </template>
        <template #text>
          <div class="w-100 h-32 ta-c fs-12 c-646">{{ $t('record') }}</div>
        </template>
      </van-grid-item>
      <van-grid-item to="/warning">
        <template #icon>
          <img src="../assets/images/warning.png" />
        </template>
        <template #text>
          <div class="w-100 h-32 ta-c fs-12 c-646">{{ $t('warning') }}</div>
        </template>
      </van-grid-item>
      <!-- <van-grid-item
        :text="$t('return')"
        :badge="returnNum | formatNumber"
        to="/return"
      >
        <template #icon>
          <img src="../assets/images/return.png" />
        </template>
      </van-grid-item> -->
      <!-- <van-grid-item
        :text="$t('inventory')"
        :badge="inventoryNum | formatNumber"
        to="/inventory"
      >
        <template #icon>
          <img src="../assets/images/inventory.png" />
        </template>
      </van-grid-item> -->
      <van-grid-item to="/machine">
        <template #icon>
          <img src="../assets/images/machine.png" />
        </template>
        <template #text>
          <div class="w-100 h-32 ta-c fs-12 c-646">{{ $t('machine') }}</div>
        </template>
      </van-grid-item>
      <van-grid-item :badge="tradeNum | formatNumber" to="/trade">
        <template #icon>
          <img src="../assets/images/trade.png" />
        </template>
        <template #text>
          <div class="w-100 h-32 ta-c fs-12 c-646">{{ $t('trade') }}</div>
        </template>
      </van-grid-item>
    </van-grid>
    <div class="z-9 fix left bottom w-100 saib">
      <van-button type="danger" block @click="$router.replace('/login')">
        {{ $t('logout') }}
      </van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      hasNew: false, // 货道变更商品
      returnNum: 0, // 退货单
      tradeNum: 0, // 交易记录补录
      inventoryNum: 0, // 盘点单
    }
  },
  async created() {
    try {
      if(localStorage.getItem('token')){
        // await this.isVmChannelChangeProduct()
        // await this.orderMsgCount()
        await this.listVmChannelTradeAdditionalLogVm()
      }else {
        this.$router.replace('/login')
      }
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    async isVmChannelChangeProduct() {
      const { data } = await this.$api.isVmChannelChangeProduct()
      console.log('isVmChannelChangeProduct===>', data)
      this.hasNew = !!data
    },
    async orderMsgCount() {
      const { data } = await this.$api.orderMsgCount()
      console.log('orderMsgCount===>', data)
      this.returnNum = data.retreatOrderCount
      this.inventoryNum = data.inventoryOrderCount
    },
    async listVmChannelTradeAdditionalLogVm() {
      const { data } = await this.$api.listVmChannelTradeAdditionalLogVm()
      console.log('listVmChannelTradeAdditionalLogVm===>', data)
      this.tradeNum = data.reduce((acc, cur) => acc + cur.additionalCount, 0)
    },
    toChannel() {
      if (this.hasNew) {
        this.$api.updateChannelChangeReadStatus().then(() => {
          this.hasNew = false
        })
      }
      this.$router.push('/channel')
    },
  },
}
</script>
<style scoped>
.van-grid-item__text {
  text-align: center;
}
</style>
